import React from 'react';
import Appointment from '../Appointment/Appointment';
import { Row, Col, Button } from 'reactstrap';
import { ReactAgenda } from 'react-agenda';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import moment from 'moment';
import Slider from "react-slick";
import {getBarbersWithAppointments, deleteAppointment, updateAppointment} from '../../api';
import HUD from '../HUD/HUD';
import PinModal from '../PinModal/PinModal';
import QRModel from '../QRModel/QRModel';

require('moment/locale/nl.js');
var colors = {
    'color-1': "rgba(200,0,0,1) 0%",
    "color-2": "rgba(200,0,0,1) 0%",
    "color-3": "rgba(200,0,0,1) 0%"
}

const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

var now = new Date();
let updated = false;
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            barbers: [],
            selected: [],
            cellHeight: 40,
            modalIsOpen: false,
            deleteModalIsOpen: false,
            editModalIsOpen: false,
            qrModalIsOpen: false,
            qrData: {},
            locale: "nl",
            rowsPerHour: 4,
            numberOfDays: 1,
            startAtTime: 9,
            startDate: moment().toDate(),
            barber: {},
            selectedDate: new Date(),
            selectedEndDate: '',
            selectedItem: {id: null, name: ""},
            value: '',
            nameValue: '',
            showPinModal: false,
            pinModalHandle: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.closeQrModal = this.closeQrModal.bind(this);
        this.changeAppointMent = this.changeAppointMent.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.handleCellSelection = this.handleCellSelection.bind(this)
        this.handleRangeSelection = this.handleRangeSelection.bind(this)
        this.fetchBarbers = this.fetchBarbers.bind(this)
        this.onItemRemove = this.onItemRemove.bind(this)
        this.setToday = this.setToday.bind(this)
        this.addDay = this.addDay.bind(this)
        this.subtractDay = this.subtractDay.bind(this);
        this.addWeek = this.addWeek.bind(this)
        this.subtractWeek = this.subtractWeek.bind(this);
        this.handleItemEdit = this.handleItemEdit.bind(this)
        this.calculateEndTime = this.calculateEndTime.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.addTd = this.addTd.bind(this);
    }

    scrollMultiple() {
        var subCatContainer = document.getElementsByClassName("agenda__table --body");
        if(subCatContainer.length > 0){
            for (let index = 0; index < subCatContainer.length; index++) {
                if(!subCatContainer[index].onscroll){
                    subCatContainer[index].onscroll = (e) => {
                        var subCatContainer = document.getElementsByClassName("agenda__table --body");
                        for (let i = 0; i < subCatContainer.length; i++) {
                            if(e.target !== subCatContainer[i]){
                                subCatContainer[i].scrollTop = e.target.scrollTop
                            }
                        }
                    }
                }
            }
        }
    }

    onScroll(){
        var subCatContainer = document.getElementsByClassName("agenda__table --body");
        for (let index = 0; index < subCatContainer.length; index++) {
            subCatContainer[index].addEventListener('scroll', () => this.scrollMultiple(subCatContainer[index]));
        }
    }

    openModal() {
        this.scrollMultiple();
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({
            modalIsOpen: false
        });
    }

    closeEditModal(){
        this.setState({
            editModalIsOpen: false
        });
    }

    closeDeleteModal(){
        this.setState({
            deleteModalIsOpen: false
        });
    }

    getValidationState() {
        const length = this.state.value.length;
        if (length > 10) return 'success';
        else if (length > 5) return 'warning';
        else if (length > 0) return 'error';
        return null;
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
    }

    handleNameChange(event){
        this.setState({
            nameValue: event.target.value
        })
    }

    async handleCellSelection(barber, item) {
        if(this.state.selected && this.state.selected[0] === item){
            return  this._openModal(item);
        }
        this.setState({
            ...this.state,  
            barber: barber,
            selected:[item] 
        })
    }

    _openModal(time){
        let endTime = moment(time).toDate();
        endTime.setMinutes(endTime.getMinutes() + 30);
        this.setState({
            ...this.state,
            modalIsOpen: true,  
            selectedDate: moment(time).toDate(),
            selectedEndDate: endTime,
            selected: [time]
        })
    }

    handleRangeSelection(startDate, endDate) {
        this.setState({
            ...this.state,
            startDate: startDate
        },
            this.fetchBarbers
        )
    }

    onItemRemove(items, item) {
        if(this.state.selected && this.state.selected._id === item._id){
            return  this._onItemRemove(item);
        }
        this.setState({
            ...this.state, 
            selected: item 
        })
    }

    _onItemRemove() {
        this.setState({
            ...this.state,
            deleteModalIsOpen: true
        })
    }

    deleteAppointMent(){
        this.setState({
            ...this.state,
            showPinModal: true,
            pinModalHandle: "delete"
        })
    }

    async changeAppointMent(item){
        this.setState({
            ...this.state,
            showPinModal: true,
            pinModalHandle: "edit",
            name: this.state.nameValue
        })
    }

    renderPincode(){
        switch (this.state.pinModalHandle) {
            case 'delete':
                return <PinModal onSuccess={async (pin) => this.handleDelete(pin)}/>
            case 'edit':
                return <PinModal onSuccess={async (pin) => this.handleEdit(pin)}/>
            default:
                break;
        }
    }
    
    async handleDelete(pin) {
        this.setState({
            ...this.state,
            deleteModalIsOpen: false,
            showPinModal: false
        }, async () => {
            await deleteAppointment(this.state.selected, pin);
            this.fetchBarbers();
        })
    }

    async handleEdit(pin) {
        const body = {
            name: this.state.nameValue,
            pin: pin
        }
        this.setState({
            ...this.state,
            editModalIsOpen: false,
            showPinModal: false
        })
        await updateAppointment(this.state.selected, body);
        await this.fetchBarbers();
    }

    
    showQRModal(qrData) {
        this.setState({
            ...this.state,
            qrModalIsOpen: true,  
            qrData: qrData
        })
    }

    closeQrModal() {
        this.setState({
            ...this.state,
            qrModalIsOpen: false,
            qrData: {}
        })
    }

    addTd(){
        var cells = document.getElementsByClassName("disable-select agenda__cell");
        for (const cell of cells) {
            cell.setAttribute("class", "agenda-hours-without");
        }
    }

    setToday(){
        let today = new Date();
        this.setState({
            ...this.state,
            startDate: today
        },
            this.fetchBarbers
        )
    }

    addDay(){
        let addWeek = moment(this.state.startDate)
        addWeek.add(1, "days")
        this.setState({
            ...this.state,
            startDate: addWeek.toDate()
        },
            this.fetchBarbers
        )
    }

    subtractDay(){
        let removeWeek = moment(this.state.startDate)
        removeWeek.subtract(1, "days")
        this.setState({
            ...this.state,
            startDate: removeWeek.toDate()
        },
            this.fetchBarbers
        )
    }

    addWeek(){
        let addWeek = moment(this.state.startDate)
        addWeek.add(7, "days")
        this.setState({
            ...this.state,
            startDate: addWeek.toDate()
        },
            this.fetchBarbers
        )
    }

    subtractWeek(){
        let removeWeek = moment(this.state.startDate)
        removeWeek.subtract(7, "days")
        this.setState({
            ...this.state,
            startDate: removeWeek.toDate()
        },
            this.fetchBarbers
        )
    }

    calculateEndTime(startTime, selection){
        let endDate = moment(this.state.selected[0]).toDate();
        switch(selection){
            case("1"):
                endDate.setMinutes(endDate.getMinutes() + 15);
                break;
            case("2"):
                endDate.setMinutes(endDate.getMinutes() + 30);
                break;
            case("3"):
                endDate.setMinutes(endDate.getMinutes() + 45);
                break;
            case("4"):
                endDate.setMinutes(endDate.getMinutes() + 60);
                break;
            case("5"):
                endDate.setMinutes(endDate.getMinutes() + 75);
                break;
            case("6"):
                endDate.setMinutes(endDate.getMinutes() + 90);
                break;
            default:
                endDate.setMinutes(endDate.getMinutes() + 30);;
                break;
        }
        return endDate;
    }

    async componentDidMount(){
        // if(!this.props.user.error && typeof this.props.user.username === 'string' && this.props.user.username.length > 0 && !updated){
            updated = true;
            await this.fetchBarbers();
        // }
        this.disableDragable();
    }

    disableDragable() {
        const trs = document.getElementsByTagName("tr");
        if(trs) {
            for (let index = 0; index < trs.length; index++) {
                trs[index].setAttribute('draggable',false);
            }
        }
    }

    async componentDidUpdate(){
        if(!this.props.user.error && typeof this.props.user.username === 'string' && this.props.user.username.length > 0 && !updated){
            updated = true;
            await this.fetchBarbers();
        }
        this.disableDragable();
        this.scrollMultiple();
    }

    async fetchBarbers(){
        const startDate = moment(this.state.startDate);
        startDate.set({hour:12,minute:0,second:0,millisecond:0})
        const encodedDate = encodeURIComponent(startDate.toISOString())
        let responseJSON = await getBarbersWithAppointments(encodedDate);
        responseJSON.forEach(barber => 
            barber.appointments.forEach(appointment => {
                appointment.startDateTime = moment(appointment.startDateTime).toDate()
                appointment.endDateTime = moment(appointment.endDateTime).toDate()
            })
        )
        this.setState({
            ...this.state,
            barbers: responseJSON
        })
    }


    handleItemEdit(item){
        if(this.state.selected && this.state.selected._id === item._id){
            return  this._onItemEdit(item);
        }
        this.setState({
            ...this.state, 
            selected: item 
        })
    }

    _onItemEdit(item){
        this.setState({
            ...this.state,
            nameValue: item.name,
            editModalIsOpen: true
        })
    }

    handleDrag(item){
        // console.log('handleRangeSelection', item)
    }

    render() {
        const barbersToShow = this.state.barbers.length > 3 ? 3 : this.state.barbers.length;
        var settings = {
            dots: false,
            slidesToShow: barbersToShow,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows:true,
                  }
                }
              ]
        };

        return (
            <div className="dashboard">
                <HUD currentDate={this.state.startDate} setToday={this.setToday.bind(this)} addWeek={this.addWeek.bind(this)} addDay={this.addDay.bind(this)} subtractWeek={this.subtractWeek.bind(this)} subtractDay={this.subtractDay.bind(this)}/>
                <Row className="remove-margin barber-background">
                    <Col md={12} className="dashboard-agenda">
                        <Slider {...settings}>
                            {this.state.barbers.map((barber, i) => {
                                return <Col md={12} key={i} className="transparent no-padding">
                                    <div className="barber-name-box">
                                        <p key={`${i},${i}`} className="text-align-center">{barber.barber}</p>
                                    </div>
                                    <ReactAgenda
                                        key={i + i + i}
                                        minDate={new Date(now.getFullYear(), now.getMonth() - 3)}
                                        maxDate={new Date(now.getFullYear(), now.getMonth() + 3)}
                                        disablePrevButton={false}
                                        startDate={this.state.startDate}
                                        startAtTime={8}
                                        endAtTime={22}
                                        cellHeight={this.state.cellHeight}
                                        locale={this.state.locale}
                                        helper={false}
                                        items={barber.appointments}
                                        numberOfDays={this.state.numberOfDays}
                                        rowsPerHour={this.state.rowsPerHour}
                                        itemColors={colors}
                                        autoScale={false}
                                        fixedHeader={true}
                                        onItemRemove={this.onItemRemove.bind(this)}
                                        onItemEdit={this.handleItemEdit.bind(this)}
                                        onRangeSelection={this.handleDrag.bind(this)}
                                        onCellSelect={this.handleCellSelection.bind(this, barber)}
                                        onDateRangeChange={this.handleRangeSelection.bind(this)}
                                        />
                                </Col>
                            })} 
                        </Slider>
                    </Col>
                    {this.renderAppointmentModal()}
                    {this.renderQRModal()}
                   {this.renderAppointmentDeleteModal()}
                   {this.renderAppointmentEditModal()}
                   {this.state.showPinModal && this.renderPincode()}

                </Row>
            </div>
        );
    }

    renderAppointmentModal(){
        return(
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Event Modal"
        >
            <div className="modal-content">
                Afspraak bij: {this.state.barber.barber}
                <Appointment barber={this.state.barber} showQRModal={(qrData) => this.showQRModal(qrData)}  fetchBarbers={this.fetchBarbers.bind(this)} closeModal={this.closeModal}   startDate={this.state.selectedDate} calculateEndTime={this.calculateEndTime}/>
            </div>
            </Modal>
        )
    }

    renderQRModal(){
        return(
            <Modal
            isOpen={this.state.qrModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeQrModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="QR Modal"
        >
            <div className="modal-content">
                <QRModel qrData={this.state.qrData} onClose={() => this.closeQrModal()}></QRModel>
            </div>
            </Modal>
        )
    }

    renderAppointmentDeleteModal() {
        return(
            <Modal
                isOpen={this.state.deleteModalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeDeleteModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Delete Event Modal"
            >
                <div className="modal-content">
                    Druk op verwijderen als u de afspraak van {this.state.selected.name} wilt verwijderen.
                    <Row className="text-align-center">
                        <Col className="col">
                            <Button className="btn-danger" onClick={() => this.deleteAppointMent(this.state.selected)}>
                                Verwijderen
                            </Button> 
                        </Col>
                        <Col className="col">
                            <Button className="btn-primary" onClick={() => this.closeDeleteModal()}>
                                Annuleren
                            </Button> 
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }

    renderAppointmentEditModal() {
        return(
                <Modal
                isOpen={this.state.editModalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeEditModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edit Event Modal"
            >
                <div className="modal-content">
                    <input value={this.state.nameValue} onChange={this.handleNameChange}></input>
                    {
                        this.state.selected._id ?
                        <div>
                        <span>Van</span>
                        <p>{this.state.selected.startDateTime.toLocaleDateString('nl-NL', options)}</p>
                        <span>Tot</span>
                        <p>{this.state.selected.endDateTime.toLocaleDateString('nl-NL', options)}</p> </div>:
                        <p>Ongeldige datum</p>
                    }
                    <Row>
                        <Col className="col">
                            <Button className="btn-danger" onClick={() => this.changeAppointMent()}>
                                Wijzigen
                            </Button> 
                        </Col>
                        <Col className="col">
                            <Button className="btn-primary" onClick={() => this.closeEditModal()}>
                                Annuleren
                            </Button> 
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    user: state.userReducer
});

export default connect(mapStateToProps)(Dashboard);
