import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {setUser} from '../../actions/User';
import { Button, FormGroup, Label, Input  } from 'reactstrap';
import './Login.scss';

let checkUser = false;
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          username: "",
          password: "",
          checkUser: false 
        }
    }

    componentDidUpdate(){
        if(checkUser){
            if(this.props.user.error){
                alert(this.props.user.error)
            }
            else{
                this.props.history.push('/dashboard')
            }
            checkUser = false;
        }
    }

    async handleSubmit(){
        this.props.onLogin(this.state);
        checkUser = true;
    }

    setUsername(e){
        this.setState({
            username: e
        })
    }

    setPassword(e){
        this.setState({
            password: e
        })
    }

    handleKeyUp(keyCode){
        if(keyCode === 13){
            this.handleSubmit();
        }
    }

     render() {
        return (
            <div className="Login">
                <h1>Mehdi's Menssalon</h1>
                    <FormGroup controlid="email">
                        <Label>Gebruiksnaam</Label>
                        <Input
                        autoFocus
                        type="text"
                        value={this.state.username}
                        onChange={e => this.setUsername(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup controlid="password">
                        <Label>Wachtwoord</Label>
                        <Input
                        value={this.state.password}
                        onChange={e => this.setPassword(e.target.value)}
                        onKeyUp={(e) => this.handleKeyUp(e.keyCode)}
                        type="password"
                        />
                    </FormGroup>
                    {/* <FormGroup controlid="password"> */}
                    <Button onClick={() => this.handleSubmit()}>
                        Login
                    </Button>
            </div>
        );
    }
}

Login.propTypes = {
    host: PropTypes.string
};

const mapStateToProps = state => ({
    user: state.userReducer
});

function mapDispatchToProps(dispatch) {
	return {
		onLogin: (body) => dispatch(setUser(body))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
