import React, { Component } from 'react'
import Header from './components/Header/Header';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { connect } from 'react-redux';
import {getUser} from './actions/User';
import {
  Switch,
  Route
} from "react-router-dom";
import Panel from './components/Panel/Panel';
import NotFound from './components/NotFound/NotFound';

let checkUser = false;
// let history= useHistory()
class App extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      updated: false
    }

    checkUser = true;
    this.props.checkLogin();
  }

  componentDidUpdate(){
    if(checkUser){
      if(this.props.user.error === "User is not signed in"){
        this.props.history.push('/')
      }
      else{
        if(this.props.history.location.pathname === "/"){
          this.props.history.push('/dashboard')
        }
      }
      checkUser = false;
      // this.setState({updated: true})
    }
  }


  render() {
    return (
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/panel" component={Panel}/>
            <Route path="/empty" component={Header}/>
            <Route component={NotFound} />
        </Switch>
        </div>
    )
  }
}

const mapStateToProps = state => ({
    user: state.userReducer
});

function mapDispatchToProps(dispatch) {
	return {
		checkLogin: () => dispatch(getUser())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);