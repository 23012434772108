import {API_URI} from '../api';
export const SET_USER = 'SET_USER';
export const FETCH_RESOURCES_FAIL = 'FETCH_RESOURCES_FAIL';

export function getUser() {
	return (dispatch => {
		fetch(`${API_URI}/users/login`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        }).then(response => {
			return response.json();
		}).then(result => {
            if(result.success){
                dispatch({
                    type: SET_USER,
                    payload: {
                        username: result.user.userName,
                        name: result.user.name,
                        email: result.user.email,
                        pin: result.user.pin,
                        status: result.user.status
                    }
                });
            }
            else{
                dispatch({type: FETCH_RESOURCES_FAIL, payload:result});
            }
		});
	});
}

export function setUser(body) {
	return (dispatch => {
		fetch(`${API_URI}/users/login`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => {
			return response.json();
		}).then(result => {
            if(result.success){
                dispatch({
                    type: SET_USER,
                    payload: {
                        username: result.user.username,
                        name: result.user.name,
                        email: result.user.email,
                        status: result.user.status
                    }
                });
            }
            else{
                dispatch({type: FETCH_RESOURCES_FAIL, payload:result});
            }
		});
	});
}

