import React, { Component } from 'react';
import Modal from 'react-modal';
import UserModal from '../UserModal/UserModal';
import { Table, Button, Row, Col } from 'reactstrap';
import './Users.scss';
import { getUsers, postUser, deleteUser } from '../../api';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "80%"
    }
};

class Users extends Component {
    constructor(props) {
        super(props)

        this.state = {
            users: [],
            user: {},
            modalIsOpen: false,
            deleteModalIsOpen: false
        }
    }

    async componentDidMount(){
        const response = await getUsers();
        this.setState({
            users: response.message
        })
    }
    
    async validateBody(body) {
        console.log(body)
        let alertMessage = "";
        const emailRegex =  /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        const strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/s
        console.log(emailRegex.test(body.email))
        if(body.name === "")
            alertMessage += "Vul een naam in\n";
        if(body.email === "" || !emailRegex.test(body.email))
            alertMessage += "Vul een geldig e-mailadres in\n";
        if(body.username === "")
            alertMessage += "Vul een gebruiksnaam in\n";
        if(body.password === "" || !strongRegex.test(body.password))
            alertMessage += "Wachtwoord moet minimaal 8 karakters lang, één hoofdletter, één kleine letter, één speciale teken en een cijfer bevatten.\n";
        else if(body.password !== body.repeatPassword)
            alertMessage += "Wachtwoorden komen niet overeen\n";
        if(body.pin.length !== 4)
            alertMessage += "Pin moet minimaal uit 4 cijfers bestaan\n";
        if(alertMessage !== "")
            alert(alertMessage);
        else {
            const response = await postUser(body);
            if(response.success){
                const newUser = response.newUser;
                const users = this.state.users;
                users.push(newUser);
                this.setState({
                    ...this.state,
                    users: users,
                    modalIsOpen: false
                })
            }
        }
    }

    componentDidUpdate(){
        
        console.log(this.state)
    }
    async deleteUser(id){
        const response = await deleteUser({_id: id});
        if(response.success === false) {
            alert(response.message);
        }
        else{
            this.setState({
                ...this.state,
                users: response.message,
                deleteModalIsOpen: false
            })
        }
    }

    selectUser(user){
        this.setState({
            ...this.state,
            user: user,
            deleteModalIsOpen: true
        })
    }

    
    render() {
        return (
            <div className="Users">
                <div className="flex-items py-2 px-2">
                    <h2 className="">Gebruikers</h2>
                    <Button color="success" size="sm" onClick={() => this.setState({modalIsOpen: true})}>Gebruiker toevoegen</Button>
                </div>
                <div className="px-2">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Naam</th>
                                <th>Gebruiksnaam</th>
                                <th>E-mail</th>
                                <th>Opties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map((user, i) => 
                                <tr key={i+1}>
                                    <th scope="row">{i+1}</th>
                                    <td>{user.name}</td>
                                    <td>{user.userName}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <Button color="danger" size="sm" onClick={() => this.selectUser(user)}>Verwijderen</Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={() => this.setState({modalIsOpen:false})}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Delete Event Modal"
                >
                    <UserModal onCancle={() => this.setState({modalIsOpen: false})} onAdd={(body) => this.validateBody(body)}/>
                </Modal>
                {this.renderUserDeleteModal()}
            </div>
        )
    }

    renderUserDeleteModal() {
        return(
            <Modal
                isOpen={this.state.deleteModalIsOpen}
                onRequestClose={() => this.setState({deleteModalIsOpen: false})}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Delete Barber Modal"
            >
                <div className="modal-content">
                    Druk op verwijderen om gebruiker {this.state.user.userName} wilt verwijderen.
                    <Row className="text-align-center">
                        <Col className="col">
                            <Button className="btn-danger" onClick={() => this.deleteUser(this.state.user._id)}>
                                Verwijderen
                            </Button> 
                        </Col>
                        <Col className="col">
                            <Button className="btn-primary" onClick={() => this.setState({deleteModalIsOpen: false})}>
                                Annuleren
                            </Button> 
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

export default Users