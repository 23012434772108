import {SET_USER, FETCH_RESOURCES_FAIL} from '../actions/User';

const initialState = {
    username: "",
	name: "",
	email: "",
	pin: "",
	status: ""
};

export default function userReducer(state = initialState, action) {
	let changes;
	switch (action.type) {
	case SET_USER:
		changes = {
			username: action.payload.username,
			name: action.payload.name,
			email: action.payload.email,
			pin: action.payload.pin,
			status: action.payload.status
		};
		return { ...changes };
	case FETCH_RESOURCES_FAIL:
		return { ...state, error: action.payload.message }
	default:
		return state;
	}
}
