import React, { Component } from 'react'
import {connect} from 'react-redux';
import './PinModal.scss';

var input   = "";

class PinModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            input: ""
        }
    }

    componentDidMount() {
        this.handlePin();
    }

    handlePin() {
        // var correct = "1593"; // pin code
        var dots    = document.querySelectorAll(".dot"), 
        numbers = document.querySelectorAll(".number");
        if(dots && numbers) {
            dots    = Array.prototype.slice.call(dots);
            numbers = Array.prototype.slice.call(numbers);
            numbers.forEach((number, index) => {
                if(!number.classList.contains('ignore')){
                    number.addEventListener('click', () => {
                        if(input.length < 4){
                            number.className += ' grow';
                            input += (index+1) >= 10 ? 0 : (index+1);
                            dots[input.length-1].className += ' active';
                            if(input.length >= 4) {
                                if(input !== this.props.pin) {
                                    dots.forEach((dot, index) => {
                                        dot.className += " wrong";
                                    });
                                    document.body.className += " wrong";
                                    if(this.props.onDelete){
                                        this.props.onDelete();
                                    }
                                }
                                else {
                                    dots.forEach((dot, index) => {
                                        dot.className += " correct";
                                    });
                                    document.body.className += " correct";
                                    if(this.props.onSuccess){
                                        this.props.onSuccess(input);
                                    }
                            }
                            setTimeout(function() {
                                dots.forEach((dot, index) => {
                                    dot.className = "dot";
                                });
                                input = "";
                            }, 900);
                            setTimeout(() => {
                                document.body.className = "";
                            }, 1000);
                        }
                            setTimeout(() => {
                                number.className = 'number';
                            }, 1000);
                        }
                    });
                }
                else{
                    number.addEventListener('click', () => {
                        if(input.length <= 0 && this.props.closeModal){
                            this.props.closeModal();
                        }
                        else{
                            input = input.slice(0, -1);
                            dots[input.length].classList.remove('active');
                        }
                    })
                }
            });
        }
    }

    onDelete(){
        
    }

    render() {
        return (
            <div id="pin">
                {this.props.title && <h5>{this.props.title}</h5>}
                <div className="dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
                <p>Enter the password</p>
                <div className="numbers">
                    <div className="number">1</div>
                    <div className="number">2</div>
                    <div className="number">3</div>
                    <div className="number">4</div>
                    <div className="number">5</div>
                    <div className="number">6</div>
                    <div className="number">7</div>
                    <div className="number">8</div>
                    <div className="number">9</div>
                    <div className="number ignore"></div>
                    <div className="number">0</div>
                    <div className="number ignore delete">X</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    pin: state.userReducer.pin
});

export default connect(mapStateToProps)(PinModal);