import React from 'react'
import QRCode from 'qrcode.react';
import { Button  } from 'reactstrap';

function QRModel(props) {
    const createCalanderEvent = () => {
        const barberName = props.qrData.barberName;
        const start = props.qrData.startDateTime;
        const end = props.qrData.endDateTime;
        // const client = props.qrData.customerName;
        const qrDate = 
        "BEGIN:VEVENT\r\n" +
        "SUMMARY:Kappersafspraak bij " + barberName +" van Men's Salon by Mehdi\r\n" +
        "DTSTART;VALUE=DATE:"+start+"\r\n" +
        "DTEND;VALUE=DATE:"+end+"\r\n"+
        "END:VEVENT\r\n" 
        
        return <QRCode value={qrDate} renderAs="svg" width={250} height={250}/>
    }

    return (
        <>
            <div style={{maxWidth: 290}}>
                <h3>Scan de QR-Code om je afspraak meteen in je agenda te zetten</h3>
            </div>
            <div style={{backgroundColor: "#ffffff", padding: 20}}>
                {createCalanderEvent()}
            </div>
            <div>
                <Button style={{width: "100%"}} className="btn-danger" onClick={() => {props.onClose()}}>Sluiten</Button>
            </div>
        </>
    )
}

export default QRModel
