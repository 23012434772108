import React, { Component } from 'react';
import Modal from 'react-modal';
import { getBarbersWithoutAppointments, postBarber, deleteBarber } from '../../api';
import { Table, Button, Row, Col } from 'reactstrap';
import './Barbers.scss';
import BarberModal from '../BarberModal/BarberModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Barbers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            barbers: [],
            barber: {},
            modalIsOpen: false,
            deleteModalIsOpen: false
        }
        
        this.getBarbers();
    }

    async getBarbers(){
        const barbers = await getBarbersWithoutAppointments();
        this.setState({
            barbers: barbers
        })
    }

    async saveBarber(body){
        const response = await postBarber(body);
        if(response.success){
            const newBarber = response.message;
            const barbers = this.state.barbers;
            barbers.push(newBarber);
            this.setState({
                ...this.state,
                barbers: barbers,
                modalIsOpen: false
            })
        }
    }

    selectBarber(barber){
        this.setState({
            ...this.state,
            barber: barber,
            deleteModalIsOpen: true
        })
    }

    async deleteBarber(barber){
        const response = await deleteBarber(barber);
        if(response.success === false) {
            alert(response.message);
        }
        else{
            const newBarbers = this.state.barbers.filter(barber => barber._id !== response.message._id);
            this.setState({
                ...this.state,
                barbers: newBarbers,
                deleteModalIsOpen: false
            })
        }
    }

    render() {
        return (
            <div className="Barbers">
                <div className="flex-items py-2 px-2">
                    <h2 className="">Barbers</h2>
                    <Button color="success" size="sm" onClick={() => this.setState({modalIsOpen: true})}>Barber toevoegen</Button>
                </div>
                <div className="px-2">
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Barber</th>
                                <th>Opties</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.barbers.map((barber, i) => 
                                <tr key={i+1}>
                                    <th scope="row">{i+1}</th>
                                    <td>{barber.barber}</td>
                                    <td>
                                        {this.state.barbers.length > 1 ? <Button color="danger" size="sm" onClick={() => this.selectBarber(barber)}>Verwijderen</Button> : null}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={() => this.setState({modalIsOpen:false})}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Delete Event Modal"
                >
                    <BarberModal onCancle={() => this.setState({modalIsOpen: false})} onAdd={(body) => this.saveBarber(body)}/>
                </Modal>
                {this.renderBarberDeleteModal()}
            </div>
        )
    }

    renderBarberDeleteModal() {
        return(
            <Modal
                isOpen={this.state.deleteModalIsOpen}
                onRequestClose={() => this.setState({deleteModalIsOpen: false})}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Delete Barber Modal"
            >
                <div className="modal-content">
                    Druk op verwijderen om barber {this.state.barber.barber} wilt verwijderen.
                    <Row className="text-align-center">
                        <Col className="col">
                            <Button className="btn-danger" onClick={() => this.deleteBarber(this.state.barber)}>
                                Verwijderen
                            </Button> 
                        </Col>
                        <Col className="col">
                            <Button className="btn-primary" onClick={() => this.setState({deleteModalIsOpen: false})}>
                                Annuleren
                            </Button> 
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

export default Barbers