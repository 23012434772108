import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input  } from 'reactstrap';
import { postAppointment } from '../../api';
import moment from 'moment';
const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }

class Appointment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            endDate: '',
            value: '2'
        }
        
        this.newEndDate = this.newEndDate.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    changeName(event){
        this.setState({
            ...this.state,
            name: event.target.value
        })
    }

    newEndDate(event){
        const endDate = this.props.calculateEndTime(this.props.startDate, event.target.value);
        this.setState({
            endDate: endDate,
            value: event.target.value,
            longEndDate: new Date(endDate).toLocaleDateString('nl-NL', options)
        })
        event.preventDefault();
    }

    checkIfMonthIsBelowTen(value){
        let newValue = value + 1;
        if(newValue < 10) {
            return "0" + newValue
        }
        else{
            return newValue; 
        }
    }

    checkIfBelowTen(value){
        if(value < 10) {
            return "0" + value
        }
        else{
            return value; 
        }
    }

    async addNewEvent() {
        const body = {
            barberId: this.props.barber._id,
            startDateTime:  moment(this.props.startDate).format(),
            endDateTime: moment(this.state.endDate).format(),
            name: this.state.name,
            classes: "color-1"
        };
        const qrResponse = {
            barberName: this.props.barber.barber,
            startDateTime:  moment(this.props.startDate).format("YYYYMMDD[T]HHmmss"),
            endDateTime: moment(this.state.endDate).format("YYYYMMDD[T]HHmmss"),
            customerName: this.state.name
        };

        if(this.state.endDate !== '' && this.state.name !== ''){
            const json = await postAppointment(body);
            if(json.success){
                this.props.closeModal();
                this.props.showQRModal(qrResponse);
                this.props.fetchBarbers();
            }
            else{
                alert("Afspraak overschrijft een ander afspraak.")
            }
        }
        else{
            alert("Voer uw naam in")
        }
    }

    componentDidMount(){
        const endDate = this.props.calculateEndTime(this.props.startDate, this.state.value);
        this.setState({
            ...this.state,
            endDate: endDate,
            longEndDate: new Date(endDate).toLocaleDateString('nl-NL', options)
        })
    }

    handleKeyUp(keyCode){
        if(keyCode === 13){
            document.activeElement.blur();
        }
    }

    render() {
        return (
            <div>
                <br/>
                    <label>
                        Naam:
                    </label>
                    <Input
                        value={this.state.name} onChange={this.changeName}
                        autoComplete="off"
                        id="name"
                        type="text"
                        label="Naam"
                        placeholder="Naam"
                        onKeyUp={(e) => this.handleKeyUp(e.keyCode)}
                    />
                    <br/>
                    <label>
                        Duur:
                    </label>
                    <Input type="select" name="select" id="duration" defaultValue={this.state.value} onChange={(e) => this.newEndDate(e)}>
                        <option value="1">15 minuten</option>
                        <option value="2">30 minuten</option>
                        <option value="3">45 minuten</option>
                        <option value="4">1 uur</option>
                    </Input>
                    <br/>                    
                    <label>
                        Van:
                    </label>
                    <p>{new Date(this.props.startDate).toLocaleDateString('nl-NL', options)}</p>
                    <label>
                        Tot:
                    </label>
                    <p>{this.state.longEndDate}</p>
                    <br/>
                    <Button onClick={() => {this.addNewEvent()}}>Maak afspraak</Button>
            </div>
        );
    }
}

Appointment.propTypes = {
    username: PropTypes.string,
    name: PropTypes.string,
    fetchBarbers: PropTypes.func
};
export default Appointment;