import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route} from 'react-router-dom';
import {compose, applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/User';

const allReducers = combineReducers({
	userReducer: userReducer
});

const store = createStore(
	allReducers,
  compose (
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Route component={App} path="/"/>
      </Router>
    </Provider>, document.getElementById('root'));
