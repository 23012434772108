import React from 'react';
import {connect} from 'react-redux';
import Header from '../Header/Header';
import {
    Switch,
    Route
} from "react-router-dom";
// import User from '../User/User';
import Users from '../Users/Users';
import Barbers from '../Barbers/Barbers';
import NotFound from '../NotFound/NotFound';

class Panel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    componentDidUpdate() {
        if(this.props.user.status !== "admin" && this.props.user !== "")
            this.props.history.push('/')
    }

    render() {
        return (
            <>
                <Header/>
                <Switch>
                    <Route exact path={this.props.match.path} render={(props) => <Barbers {...props} onSuccess={() => {console.log("Success")}} />}/>
                    <Route path={`${this.props.match.path}/barbers`} 
                    render={(props) => <Barbers {...props} onSuccess={() => {console.log("Success")}} />}/>
                    
                    <Route path={`${this.props.match.path}/gebruikers`} 
                    render={(props) => <Users {...props} onSuccess={() => {console.log("Success")}} />}/>
                    
                    <Route component={NotFound} />
                </Switch>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.userReducer
});

export default connect(mapStateToProps)(Panel);

